<template>
  <div id="left" v-if="showBtn">
    <div class="box1 shous">
      <div class="box1-hei"></div>
      <div class="box1-hong">
        <div class="box1-hong-poa">
          <div class="flex ALcenter JCcenter">
            <div class="box1-hong-poa-ma"></div>
          </div>
          <div class="tac fs14 hui333">公众号二维码</div>
        </div>
      </div>
    </div>
    <div class="box2 shous">
      <div class="box2-hei"></div>
      <div class="box2-hong">
        <div class="box2-hong-poa flex" style="display: flex">
          <div class="box2-hong-poa-box">
            <div class="flex ALcenter JCcenter">
              <div class="box2-hong-poa-box-and"></div>
            </div>
            <div class="tac fs14 hui333">Android下载</div>
          </div>
          <div class="box2-hong-poa-box">
            <div class="flex ALcenter JCcenter">
              <div class="box2-hong-poa-box-ios"></div>
            </div>
            <div class="tac fs14 hui333">IOS下载</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="box3">
      <div class="box3-hei"></div>
      <div class="box3-hong"></div>
    </div> -->
    <div class="box4 shous">
      <div class="box4-hei"></div>
      <div class="box4-hong" @click="toTop"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Left",
  data() {
    return {
      showBtn: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    showbtn() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (scrollTop > 600) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
#left {
  position: fixed;
  top: 406px;
  right: 33px;
  width: 60px;
  z-index: 999;
  .box1 {
    width: 60px;
    height: 60px;
    // background-image: url(~@/assets/box5.png);
    // background-size: 100% 100%;
    margin-bottom: 20px;
    .box1-hei {
      width: 60px;
      height: 60px;
      background-image: url(~@/assets/box5.png);
      background-size: 100% 100%;
      display: block;
    }
    .box1-hong {
      width: 60px;
      height: 60px;
      background-image: url(~@/assets/box1.png);
      background-size: 100% 100%;
      display: none;
      position: relative;
      .box1-hong-poa {
        position: absolute;
        right: 150%;
        bottom: 0;
        width: 166px;
        height: 166px;
        display: none;
        background: #fff;
        box-shadow: 4px 4px 8px 1px rgba(18, 17, 39, 0.05),
          -4px -4px 8px 1px rgba(18, 17, 39, 0.05);
        border-radius: 10px 10px 10px 10px;
        .box1-hong-poa-ma {
          margin-top: 10px;
          margin-block: 10px;
          width: 113px;
          height: 113px;
          background-image: url(~@/assets/gongzhonghao.png);
          background-size: 100% 100%;
        }
      }
    }
    .box1-hong:hover .box1-hong-poa {
      display: block;
    }
  }
  .box1:hover .box1-hei {
    display: none;
  }
  .box1:hover .box1-hong {
    display: block;
  }
  .box2 {
    width: 60px;
    height: 60px;
    // background-image: url(~@/assets/box6.png);
    // background-size: 100% 100%;
    margin-bottom: 20px;
    .box2-hei {
      width: 60px;
      height: 60px;
      background-image: url(~@/assets/box6.png);
      background-size: 100% 100%;
      display: block;
    }
    .box2-hong {
      width: 60px;
      height: 60px;
      background-image: url(~@/assets/box2.png);
      background-size: 100% 100%;
      display: none;
      position: relative;
      .box2-hong-poa {
        position: absolute;
        right: 150%;
        bottom: 0;
        width: 332px;
        height: 166px;
        display: none;
        background: #fff;
        box-shadow: 4px 4px 8px 1px rgba(18, 17, 39, 0.05),
          -4px -4px 8px 1px rgba(18, 17, 39, 0.05);
        border-radius: 10px 10px 10px 10px;
        .box2-hong-poa-box {
          width: 166px;
          height: 166px;
          .box2-hong-poa-box-and {
            margin-top: 10px;
            margin-block: 10px;
            width: 113px;
            height: 113px;
            background-image: url(~@/assets/Android.png);
            background-size: 100% 100%;
          }
          .box2-hong-poa-box-ios {
            margin-top: 10px;
            margin-block: 10px;
            width: 113px;
            height: 113px;
            background-image: url(~@/assets/IOS.png);
            background-size: 100% 100%;
          }
        }
      }
    }
    .box2-hong:hover .box2-hong-poa {
      display: block;
    }
  }
  .box2:hover .box2-hei {
    display: none;
  }
  .box2:hover .box2-hong {
    display: block;
  }
  .box3 {
    width: 60px;
    height: 60px;
    // background-image: url(~@/assets/box7.png);
    // background-size: 100% 100%;
    margin-bottom: 20px;
    .box3-hei {
      width: 60px;
      height: 60px;
      background-image: url(~@/assets/box7.png);
      background-size: 100% 100%;
      display: block;
    }
    .box3-hong {
      width: 60px;
      height: 60px;
      background-image: url(~@/assets/box3.png);
      background-size: 100% 100%;
      display: none;
    }
  }
  .box3:hover .box3-hei {
    display: none;
  }
  .box3:hover .box3-hong {
    display: block;
  }
  .box4 {
    width: 60px;
    height: 60px;
    // background-image: url(~@/assets/box8.png);
    // background-size: 100% 100%;
    margin-bottom: 20px;
    .box4-hei {
      width: 60px;
      height: 60px;
      background-image: url(~@/assets/box8.png);
      background-size: 100% 100%;
      display: block;
    }
    .box4-hong {
      width: 60px;
      height: 60px;
      background-image: url(~@/assets/box4.png);
      background-size: 100% 100%;
      display: none;
    }
  }
  .box4:hover .box4-hei {
    display: none;
  }
  .box4:hover .box4-hong {
    display: block;
  }
}
</style>