import Vue from 'vue'
import Router from 'vue-router'
// import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(Router)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  }, {
    path: '/resident',
    name: 'Resident',
    component: () => import('../views/Resident.vue'),
    meta: {
      title: '居民服务'
    }
  },
  {
    path: '/property',
    name: 'Property',
    component: () => import('../views/Property.vue'),
    meta: {
      title: '物业服务'
    }
  },
  {
    path: '/estate',
    name: 'Estate',
    component: () => import('../views/Estate.vue'),
    meta: {
      title: '小区服务'
    }
  },
  {
    path: '/housekeeping',
    name: 'Housekeeping',
    component: () => import('../views/Housekeeping.vue'),
    meta: {
      title: '家政服务'
    }
  },
  {
    path: '/introduce',
    name: 'Introduce',
    component: () => import('../views/Introduce.vue'),
    meta: {
      title: '公司介绍'
    }
  },
  {
    path: '/softening',
    name: 'Softening',
    component: () => import('../views/Softening.vue'),
    meta: {
      title: '公司软著'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import('../views/Information.vue'),
    meta: {
      title: '新闻资讯'
    }
  },
  {
    path: '/business',
    name: 'Business',
    component: () => import('../views/Business.vue'),
    meta: {
      title: '商务合作'
    }
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/Download.vue'),
    meta: {
      title: 'APP下载'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue'),
    meta: {
      title: '新闻动态'
    }
  },
  {
    path: '/news/details',
    name: 'Details',
    component: () => import('../views/Details.vue'),
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/qtdj',
    name: 'qtdj',
    component: () => import('../views/qtdj.vue'),
    meta: {
      title: '蜻蜓到家'
    }
  },
]

// const router = new VueRouter({
//   mode: 'hash',
//   base: process.env.BASE_URL,
//   routes
// })

const createRouter = () => new Router({
  mode: 'hash',
  // mode: 'history', // 去掉#，需要路由模式改为history
  base: process.env.BASE_URL,
  // base: '/dist/',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes // 实例化vue的时候只挂载constantRouter，其余的按照用户权限动态挂载
})
const router = createRouter()

// router.beforeEach((to) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
// });

export default router