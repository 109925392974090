<template>
  <div id="Bottombox">
    <div class="body flex JCspaceBetween ALcenter">
      <div class="fs16">
        <div class="white fw6">了解我们</div>
        <div class="body-top hui999 shous" @click="xiaoda">首页</div>
        <div class="body-top hui999 shous" @click="fuwu">产品服务</div>
        <div class="body-top hui999 shous" @click="godongt">新闻动态</div>
        <div class="body-top hui999 shous" @click="guanyu">关于我们</div>
      </div>
      <div class="fs16">
        <div class="body-top hui999 shous" @click="hezuo">商务合作</div>
        <div class="body-top hui999 shous" @click="xiazai">APP下载</div>
      </div>
      <div>
        <div class="white fw6 fs16">联系我们</div>
        <div class="body-top flex ALcenter">
          <div class="hui999 fs16">电话：</div>
          <div class="white fw6 fs32">0571-56308881</div>
        </div>
        <div class="body-top fs16 hui999">
          地址：杭州市上城区中豪五星国际4幢9楼
        </div>
      </div>
      <div class="fs16 body-dow">
        <!-- <img class="body-dow-imgboxs" src="@/assets/gongzhonghao.png" /> -->
        <div class="body-top body-dow-img tac hui999 shous">
          <img class="body-dow-imgbox" src="@/assets/Android.png" />
          安卓下载
        </div>
        <div class="body-top body-dow-imgs tac hui999 shous">
          <img class="body-dow-imgboxs" src="@/assets/IOS.png" />
          IOS下载
        </div>
      </div>
      <div>
        <img src="@/assets/gongzhonghao.png" />
        <div class="tac fs14 white">公众号二维码</div>
      </div>
    </div>
    <div class="Bottombox-bt"></div>
    <div class="Bottombox-body">
      <div class="Bottombox-body-top flex JCcenter ALcenter">
        <!-- <div class="Bottombox-body-top-xiaoda shous" @click="xiaoda"></div>
        <div class="hui999" style="margin: 0 38px">|</div> -->
        <div class="Bottombox-body-top-juda shous" @click="gojuda"></div>
      </div>
      <div class="tac fs16 hui999">杭州聚达物联科技有限公司</div>
      <div class="tac fs16 hui999" style="margin-top: 10px">
        浙公网安备 33010402004369号 浙ICP备20001857号-1
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottombox",
  data() {
    return {
      and: "",
    };
  },
  methods: {
    xiaoda() {
      this.$router.push("/");
    },
    fuwu() {
      this.$router.push("/resident");
    },
    guanyu() {
      this.$router.push("/introduce");
    },
    hezuo() {
      this.$router.push("/business");
    },
    xiazai() {
      this.$router.push("/download");
    },
    godongt() {
      this.$router.push("/news");
    },
    gojuda() {
      window.open('http://www.judawulian.com/', '_blank');
    }
  },
};
</script>

<style lang="less" scoped>
#Bottombox {
  width: 1920px;
  height: 540px;
  background: #313136;
  .body {
    width: 1200px;
    margin: 0 360px;
    height: 353px;
    .body-top {
      margin-top: 30px;
    }
    .body-dow {
      position: relative;
      .body-dow-img {
        .body-dow-imgbox {
          width: 166px;
          height: 166px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -180%;
          display: none;
        }
      }
      .body-dow-img:hover .body-dow-imgbox {
        display: block;
      }
      .body-dow-imgs {
        .body-dow-imgboxs {
          width: 166px;
          height: 166px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -180%;
          display: none;
        }
      }
      .body-dow-imgs:hover .body-dow-imgboxs {
        display: block;
      }
    }
  }
  .Bottombox-bt {
    width: 1920px;
    height: 1px;
    background: rgba(153, 153, 153, 0.1);
  }
  .Bottombox-body {
    height: 186px;
    .Bottombox-body-top {
      height: 120px;
      .Bottombox-body-top-xiaoda {
        width: 149px;
        height: 36px;
        background-image: url(~@/assets/linjiaxiaodaicon.png);
        background-size: 100% 100%;
      }

      .Bottombox-body-top-juda {
        width: 203px;
        height: 42px;
        background-image: url(~@/assets/judaicon.png);
        background-size: 100% 100%;
      }
    }
  }
}
</style>