<template>
  <div id="Fuwu"></div>
</template>

<script>
export default {
  name: "Fuwu",
};
</script>

<style lang="less" scoped>
#Fuwu {
  width: 1920px;
  height: 240px;
  background-image: url(~@/assets/fuwu-bg.png);
  background-size: 100% 100%;
}
</style>