/* eslint-disable vue/require-v-for-key */
<template>
  <div id="home">
    <!-- <Tabbar /> -->
    <!--      轮播图-->
    <div class="carousel-map">
      <el-carousel
        :interval="4000"
        style="width: 100%"
        arrow="always"
        height="780px"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
            :class="className"
            fit="cover"
            style="width: 100%; height: 100%"
            :src="bannerdd.length !== 0?'https://gxzw.linjiaxiaoda.com/picture/prod-api' + item.image:item.image"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 内容 -->
    <div class="content" style="margin-top: 100px">
      <p class="tac fs36 fw6 content-p">聚达之家</p>
      <div class="flex JCcenter content-tiao">
        <div class="content-tiao-L"></div>
        <div class="content-tiao-R"></div>
      </div>
      <div class="flex JCcenter" style="margin-top: 60px">
        <p class="tac hui333 content-p2 hui333">
          打造智慧小区“IOT”运营管理平台，通过数字化赋能，实现物业现代数字化建设，完善并提高物业与业主生活共同，构建智慧城市新业态。
        </p>
      </div>
      <div class="flex JCspaceBetween" style="margin-top: 52px">
        <div v-for="(item, index) in contentOne" :key="index">
          <div class="content-One">
            <div class="flex JCcenter">
              <img :src="item.url" alt="" />
            </div>
            <div class="tac fs24 hui333 fw5" style="margin: 20px 0">
              {{ item.name }}
            </div>
            <div class="tac hui666" style="line-height: 32px">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <p class="tac fs36 fw6 content-p">我们的优势</p>
      <div class="flex JCcenter content-tiao">
        <div class="content-tiao-L"></div>
        <div class="content-tiao-R"></div>
      </div>
      <div style="margin-top: 80px" class="flex JCspaceBetween">
        <div class="content-Two flex">
          <div class="content-Two-imgbox flex JCcenter ALcenter">
            <img class="content-Two-imgbox-img" src="@/assets/home1.png" />
          </div>
          <div class="content-Two-textbox">
            <div class="flex ALcenter content-Two-textbox-title">
              <div class="content-Two-textbox-title-tiao"></div>
              <div class="fs24 fw6 hui333" style="margin-left: 20px">
                精准聚集
              </div>
            </div>
            <div class="content-Two-textbox-text fs16 hui999">
              重点聚集核心物管能力，服务系统轻量化，物管与住户学习成本更低。
            </div>
          </div>
        </div>
        <div class="content-Two flex">
          <div class="content-Two-imgbox flex JCcenter ALcenter">
            <img class="content-Two-imgbox-img" src="@/assets/home2.png" />
          </div>
          <div class="content-Two-textbox">
            <div class="flex ALcenter content-Two-textbox-title">
              <div class="content-Two-textbox-title-tiao"></div>
              <div class="fs24 fw6 hui333" style="margin-left: 20px">
                全场景联动
              </div>
            </div>
            <div class="content-Two-textbox-text fs16 hui999">
              软硬件一体化联动，实现多场景互动，物业缴费与人行、车行联动
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 0px" class="flex JCspaceBetween">
        <div class="content-Two flex">
          <div class="content-Two-textbox">
            <div class="flex ALcenter content-Two-textbox-title">
              <div class="content-Two-textbox-title-tiao"></div>
              <div class="fs24 fw6 hui333" style="margin-left: 20px">
                合作方式灵活
              </div>
            </div>
            <div class="content-Two-textbox-text fs16 hui999">
              合作模式灵活多样，可根据实际情况选择合作模式。
            </div>
          </div>
          <div class="content-Two-imgbox flex JCcenter ALcenter">
            <img class="content-Two-imgbox-img" src="@/assets/home3.png" />
          </div>
        </div>
        <div class="content-Two flex">
          <div class="content-Two-textbox">
            <div class="flex ALcenter content-Two-textbox-title">
              <div class="content-Two-textbox-title-tiao"></div>
              <div class="fs24 fw6 hui333" style="margin-left: 20px">
                生活服务自运营
              </div>
            </div>
            <div class="content-Two-textbox-text fs16 hui999">
              围绕家庭供需两侧纵深发展，为用户提供家庭服务，旨在打造数字化、线上化、专业化、贴心化
              的生活服务。
            </div>
          </div>
          <div class="content-Two-imgbox flex JCcenter ALcenter">
            <img class="content-Two-imgbox-img" src="@/assets/home4.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="content">
      <p class="tac fs36 fw6 content-p">新闻资讯</p>
      <div class="flex JCcenter content-tiao">
        <div class="content-tiao-L"></div>
        <div class="content-tiao-R"></div>
      </div>
      <!-- 内容 -->
      <div class="content-three flex" style="margin-top: 80px">
        <!-- 左边新闻 -->
        <div v-for="(item, index) in contentTwo" :key="index">
          <template v-if="index < 2">
            <div class="content-three-box shous" @click="goxiang(item.id)">
              <img
                :src="'https://gxzw.linjiaxiaoda.com/picture/prod-api' + item.cover"
                mode="aspectFill"
                class="content-three-box-img"
              />
              <div class="content-three-box-time fs14 hui999">
                {{ item.createTime }}
              </div>
              <div class="content-three-box-name fs20 hui333 fw6 textOne">
                {{ item.title }}
              </div>
              <div class="content-three-box-text fs16 hui666 text5">
                {{ item.withTitle }}
              </div>
            </div>
          </template>
        </div>
        <!-- 右边新闻 -->
        <div class="content-three-boxR" v-if="contentTwo.length > 2">
          <div style="height: 50px"></div>
          <div v-for="(item, index) in contentTwo" :key="index">
            <template v-if="index > 1 && index < 5">
              <div
                class="content-three-boxR-box shou"
                @click="goxiang(item.id)"
              >
                <div
                  class="
                    content-three-boxR-box-name
                    textTwo
                    fs20
                    hui333
                    fw6
                    shous
                  "
                >
                  {{ item.title }}
                </div>
                <div class="fs14 hui999" style="margin-top: 8px">
                  {{ item.createTime }}
                </div>
              </div>
            </template>
          </div>
          <div class="flex jRight content-three-boxR-more">
            <div class="flex shous ALcenter" @click="goxinwen">
              <div class="lan499 fs16">查看更多</div>
              <img
                src="@/assets/lanIcon.png"
                class="content-three-boxR-more-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="content">
      <p class="tac fs36 fw6 content-p">合作伙伴</p>
      <div class="flex JCcenter content-tiao">
        <div class="content-tiao-L"></div>
        <div class="content-tiao-R"></div>
      </div>
      <!-- 内容 -->
      <div class="content-imgs"></div>
      <!-- <img src="@/assets/huoban.png" class="content-imgs" /> -->
    </div>
    <Fuwu />
    <!-- <Bottombox /> -->
  </div>
</template>

<script>
// import Tabbar from "@/components/Tabbar.vue";
// import Bottombox from "@/components/Bottombox.vue";
import Fuwu from "@/components/Fuwu.vue";
import { getList,getBanner } from "@/api/new";
export default {
  name: "Home",
  components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
  data() {
    return {
      bannerList: [{ id: 1, image: require("@/assets/banner-home.png") }],
      bannerdd: [],
      className: "", //轮播图名字
      contentOne: [
        {
          name: "统一管理  全岗有效",
          text: "全方位构建智慧社区服务运营体系",
          url: require("@/assets/contentOne1.png"),
        },
        {
          name: "贴心服务  优质体验",
          text: "足不出户，在线缴费",
          url: require("@/assets/contentOne2.png"),
        },
        {
          name: "防控联动  安全升级",
          text: "异常事件自动报警推送，实现社区有效安全管控。",
          url: require("@/assets/contentOne3.png"),
        },
      ],
      contentTwo: [],
    };
  },
  mounted() {
    this.getlist();
    this.getbanner()
  },
  methods: {
    /**
     * 获取列表
     */
    getlist() {
      let obj = {};
      getList(obj).then((res) => {
        // console.log(res);
        this.contentTwo = res.data;
      });
    },

    goxinwen() {
      this.$router.push("/news");
    },
    goxiang(e) {
      this.$router.push({ path: "/news/details", query: { id: e } });
    },
    getbanner() {
      getBanner({type: 1}).then(res => {
        console.log(res.data,'banner');
        this.bannerdd = res.data
        if(res.data.length !== 0) {
          this.bannerList = res.data
        }
      })
    }
  },
};
</script>

<style scoped lang="less">
#home {
  width: 1920px;
  // height: 800px;
  .carousel-map {
    width: 1920px;
    height: 780px;
    overflow: hidden;
    .lun-img {
      transform: scale(1.5);
    }
    .lun-img-two {
      transition: all 3s;
      transform: scale(1);
    }
    .el-carousel__item.is-animating {
      transition: all 0.6s;
    }
  }
  .content {
    width: 1264px;
    height: 808px;
    margin: 0 328px;
    .content-tiao {
      width: 1264px;
      height: 6px;
      margin-top: 21px;
      .content-tiao-L {
        width: 64px;
        height: 6px;
        background: #f8951d;
        margin-right: 8px;
      }
      .content-tiao-R {
        width: 12px;
        height: 6px;
        background: #f8951d;
      }
    }

    .content-p {
      font-family: PingFang SC-Semibold, PingFang SC;
    }

    .content-p2 {
      width: 878px;
      line-height: 36px;
    }

    .content-One {
      width: 240px;
    }

    .content-Two {
      width: 600px;
      .content-Two-imgbox {
        width: 300px;
        height: 255px;
        .content-Two-imgbox-img {
          width: 150px;
          height: 130px;
        }
      }
      .content-Two-textbox {
        width: 300px;
        height: 255px;
        .content-Two-textbox-title {
          width: 300px;
          height: 68px;
          .content-Two-textbox-title-tiao {
            width: 3px;
            height: 28px;
            background: #f8951d;
          }
        }

        .content-Two-textbox-text {
          width: 236px;
          margin: 0 41px 0 23px;
          line-height: 26px;
        }
      }
    }

    .content-three {
      width: 1264px;
      height: 516px;

      .content-three-box {
        width: 360px;
        height: 516px;
        background: #fff;
        overflow: hidden;
        box-shadow: 5px 20px 50px 1px rgba(18, 17, 39, 0.08),
          0px -1px 13px 1px rgba(18, 17, 39, 0.08);
        border-radius: 12px 12px 12px 12px;
        .content-three-box-img {
          width: 360px;
          height: 201px;
        }
        .content-three-box-time {
          width: 312px;
          height: 32px;
          margin: 30px 24px 0;
        }
        .content-three-box-name {
          width: 312px;
          height: 52px;
          margin: 30px 24px 0;
        }

        .content-three-box-text {
          width: 312px;
          height: 145px;
          line-height: 29px;
          margin: 0 24px 0;
        }
      }
      .content-three-box:nth-child(1) {
        margin-left: 24px;
      }

      .content-three-boxR {
        width: 430px;
        height: 516px;
        margin-left: 24px;
        background: #ffffff;
        box-shadow: 5px 20px 50px 1px rgba(18, 17, 39, 0.08),
          0px -1px 13px 1px rgba(18, 17, 39, 0.08);
        border-radius: 12px 12px 12px 12px;
        .content-three-boxR-box {
          width: 401px;
          height: 118px;
          margin-left: 14px;
          border-bottom: 1px solid #f6f6f6;
          margin-bottom: 20px;
          .content-three-boxR-box-name {
            width: 383px;
            height: 58px;
            line-height: 28px;
          }
        }

        .content-three-boxR-more {
          width: 383px;
          margin-left: 14px;
          .content-three-boxR-more-icon {
            width: 6px;
            height: 10px;
            margin-left: 8px;
          }
        }
      }
    }

    .content-imgs {
      width: 1264px;
      height: 426px;
      margin-top: 80px;
      background-image: url(~@/assets/huoban.png);
      background-size: 100% 100%;
    }
  }
}
</style>