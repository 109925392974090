var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"tabbar"}},[_c('div',{staticClass:"tab flex ALcenter JCspaceBetween"},[_c('div',{staticClass:"tab-icon shous",on:{"click":_vm.shouye}}),_c('div',{staticClass:"flex ALcenter JCspaceBetween tab-box fs16"},[_c('div',{staticClass:"tab-box-li shous",class:[_vm.names == '首页' ? 'redf89 fw5' : 'hui555'],on:{"click":function($event){return _vm.nameClick('首页')}}},[_vm._v(" 首页 "),(_vm.names == '首页')?_c('div',{staticClass:"tab-box-li-tiao"}):_vm._e()]),_c('div',{staticClass:"tab-box-li shous tab-box-poup",class:[
           _vm.names == '居民服务' ||
           _vm.names == '物业服务' ||
           _vm.names == '小区服务' ||
           _vm.names == '家政服务'
             ? 'redf89 fw5'
             : 'hui555' ]},[_vm._v(" 产品服务 "),(
             _vm.names == '居民服务' ||
             _vm.names == '物业服务' ||
             _vm.names == '小区服务' ||
             _vm.names == '家政服务'
           )?_c('div',{staticClass:"tab-box-li-tiao"}):_vm._e(),_c('div',{staticClass:"tac",attrs:{"id":"no"}},[_c('p',{class:_vm.names == '居民服务' ? 'ps' : '',on:{"click":function($event){return _vm.nameClick('居民服务')}}},[_vm._v(" 居民服务 ")]),_c('p',{class:_vm.names == '物业服务' ? 'ps' : '',on:{"click":function($event){return _vm.nameClick('物业服务')}}},[_vm._v(" 物业服务 ")]),_c('p',{class:_vm.names == '小区服务' ? 'ps' : '',on:{"click":function($event){return _vm.nameClick('小区服务')}}},[_vm._v(" 小区服务 ")]),_c('p',{class:_vm.names == '家政服务' ? 'ps' : '',on:{"click":function($event){return _vm.nameClick('家政服务')}}},[_vm._v(" 家政服务 ")])])]),_c('div',{staticClass:"tab-box-li shous",class:[_vm.names == '新闻动态' ? 'redf89 fw5' : 'hui555'],on:{"click":function($event){return _vm.nameClick('新闻动态')}}},[_vm._v(" 新闻动态 "),(_vm.names == '新闻动态')?_c('div',{staticClass:"tab-box-li-tiao"}):_vm._e()]),_c('div',{staticClass:"tab-box-li shous tab-box-poup",class:[
           _vm.names == '公司介绍' ||
           _vm.names == '公司软著' ||
           _vm.names == '联系我们' ||
           _vm.names == '新闻资讯'
             ? 'redf89 fw5'
             : 'hui555' ]},[_vm._v(" 关于我们 "),(
             _vm.names == '公司介绍' ||
             _vm.names == '公司软著' ||
             _vm.names == '联系我们' ||
             _vm.names == '新闻资讯'
           )?_c('div',{staticClass:"tab-box-li-tiao"}):_vm._e(),_c('div',{staticClass:"tac",attrs:{"id":"no"}},[_c('p',{class:_vm.names == '公司介绍' ? 'ps' : '',on:{"click":function($event){return _vm.nameClick('公司介绍')}}},[_vm._v(" 公司介绍 ")]),_c('p',{class:_vm.names == '公司软著' ? 'ps' : '',on:{"click":function($event){return _vm.nameClick('公司软著')}}},[_vm._v(" 公司软著 ")]),_c('p',{class:_vm.names == '联系我们' ? 'ps' : '',on:{"click":function($event){return _vm.nameClick('联系我们')}}},[_vm._v(" 联系我们 ")]),_c('p',{class:_vm.names == '新闻资讯' ? 'ps' : '',on:{"click":function($event){return _vm.nameClick('新闻资讯')}}},[_vm._v(" 新闻资讯 ")])])]),_c('div',{staticClass:"tab-box-li shous",class:[_vm.names == '商务合作' ? 'redf89 fw5' : 'hui555'],on:{"click":function($event){return _vm.nameClick('商务合作')}}},[_vm._v(" 商务合作 "),(_vm.names == '商务合作')?_c('div',{staticClass:"tab-box-li-tiao"}):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }