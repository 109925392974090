import request from '@/utils/request'
/**
 * 获取新闻列表接口
 */
export function getList(query) {
    return request({
      url: `/system/journalism/top`,
      method: 'get',
      params: query
    })
  }

  /**
   * 全部新闻列表 带分类
   */
   export function getAllList(query) {
    return request({
      url: `/system/journalism/home`,
      method: 'get',
      params: query
    })
  }

  /**
   * 获取详情
   */
   export function getAllInfo(query) {
    return request({
      url: `/system/journalism/info`,
      method: 'get',
      params: query
    })
  }

    /**
   * 获取banner
   */
     export function getBanner(query) {
      return request({
        url: `/system/chart/banner`,
        method: 'get',
        params: query
      })
    }
