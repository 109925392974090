<template>
  <div id="app">
    <Tabbar />
    <router-view />
    <Left />
    <Bottombox />
  </div>
</template>
<script>
import Tabbar from "@/components/Tabbar.vue";
import Bottombox from "@/components/Bottombox.vue";
import Left from "@/components/Left.vue";
// import Fuwu from "@/components/Fuwu.vue";

export default {
  components: {
    Tabbar,
    Bottombox,
    Left,
    // Fuwu,
  },
};
</script>

<style lang="less">
</style>
