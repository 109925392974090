import axios from 'axios'
import { Message } from 'element-ui'
import qs from 'qs'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 6000000 // request timeout
})

service.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      config.paramsSerializer = function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.headers['content-type'] === 'application/octet-stream' || response.request.responseType === 'blob') {
      return res
    } else {
      console.log(res.code)
      if (Number(res.code) !== 200) {
        console.log(res, 'res')
        Message({
          message: res.message || res.msg || 'error',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(res)
      } else {
        return res
      }
    }
  },
  error => {
    // console.log(process.env.VUE_APP_BASE_API);
    console.log('err111111111111' + error) // for debug
    Message({
      message: '未知错误',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
