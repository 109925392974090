<template>
  <!-- 
    导航页
 -->
  <div id="tabbar">
    <!-- <h1>我的</h1> -->
    <div class="tab flex ALcenter JCspaceBetween">
      <div class="tab-icon shous" @click="shouye" />
      <div class="flex ALcenter JCspaceBetween tab-box fs16">
        <div
          class="tab-box-li shous"
          :class="[names == '首页' ? 'redf89 fw5' : 'hui555']"
          @click="nameClick('首页')"
        >
          首页
          <div v-if="names == '首页'" class="tab-box-li-tiao"></div>
        </div>
        <div
          class="tab-box-li shous tab-box-poup"
          :class="[
            names == '居民服务' ||
            names == '物业服务' ||
            names == '小区服务' ||
            names == '家政服务'
              ? 'redf89 fw5'
              : 'hui555',
          ]"
        >
          产品服务
          <div
            v-if="
              names == '居民服务' ||
              names == '物业服务' ||
              names == '小区服务' ||
              names == '家政服务'
            "
            class="tab-box-li-tiao"
          ></div>
          <!-- 产品服务四个多余的选择 -->
          <div id="no" class="tac">
            <!-- <router-link to="/resident"> -->
            <p
              @click="nameClick('居民服务')"
              :class="names == '居民服务' ? 'ps' : ''"
            >
              居民服务
            </p>
            <!-- </router-link> -->
            <p
              @click="nameClick('物业服务')"
              :class="names == '物业服务' ? 'ps' : ''"
            >
              物业服务
            </p>
            <p
              @click="nameClick('小区服务')"
              :class="names == '小区服务' ? 'ps' : ''"
            >
              小区服务
            </p>
            <p
              @click="nameClick('家政服务')"
              :class="names == '家政服务' ? 'ps' : ''"
            >
              家政服务
            </p>
          </div>
        </div>
        <div
          class="tab-box-li shous"
          :class="[names == '新闻动态' ? 'redf89 fw5' : 'hui555']"
          @click="nameClick('新闻动态')"
        >
          新闻动态
          <div v-if="names == '新闻动态'" class="tab-box-li-tiao"></div>
        </div>
        <div
          class="tab-box-li shous tab-box-poup"
          :class="[
            names == '公司介绍' ||
            names == '公司软著' ||
            names == '联系我们' ||
            names == '新闻资讯'
              ? 'redf89 fw5'
              : 'hui555',
          ]"
        >
          关于我们
          <div
            v-if="
              names == '公司介绍' ||
              names == '公司软著' ||
              names == '联系我们' ||
              names == '新闻资讯'
            "
            class="tab-box-li-tiao"
          ></div>
          <!-- 产品服务四个多余的选择 -->
          <div id="no" class="tac">
            <!-- <router-link to="/resident"> -->
            <p
              @click="nameClick('公司介绍')"
              :class="names == '公司介绍' ? 'ps' : ''"
            >
              公司介绍
            </p>
            <!-- </router-link> -->
            <p
              @click="nameClick('公司软著')"
              :class="names == '公司软著' ? 'ps' : ''"
            >
              公司软著
            </p>
            <p
              @click="nameClick('联系我们')"
              :class="names == '联系我们' ? 'ps' : ''"
            >
              联系我们
            </p>
            <p
              @click="nameClick('新闻资讯')"
              :class="names == '新闻资讯' ? 'ps' : ''"
            >
              新闻资讯
            </p>
          </div>
        </div>
        <div
          class="tab-box-li shous"
          :class="[names == '商务合作' ? 'redf89 fw5' : 'hui555']"
          @click="nameClick('商务合作')"
        >
          商务合作
          <div v-if="names == '商务合作'" class="tab-box-li-tiao"></div>
        </div>
        <!-- <div
          class="tab-box-li shous"
          :class="[names == 'APP下载' ? 'redf89 fw5' : 'hui555']"
          @click="nameClick('APP下载')"
        >
          APP下载
          <div v-if="names == 'APP下载'" class="tab-box-li-tiao"></div>
        </div>
        <div
          class="tab-box-li shous"
          :class="[names == '蜻蜓到家' ? 'redf89 fw5' : 'hui555']"
          @click="nameClick('蜻蜓到家')"
        >
          蜻蜓到家
          <div v-if="names == '蜻蜓到家'" class="tab-box-li-tiao"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import router from "@/router";
export default {
  name: "Tabbar",
  data() {
    return {
      names: "首页",
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        //判断newVal有没有值监听路由变化
        // console.log(newVal.meta.title);
        this.names = newVal.meta.title;
      },
    },
  },
  mounted() {},
  methods: {
    nameClick(e) {
      this.names = e;
      console.log(e);
      if (e == "居民服务") {
        // this.$router.push({path: "/register"})
        this.$router.push("/resident");
      } else if (e == "首页") {
        this.$router.push("/");
      } else if (e == "物业服务") {
        this.$router.push("/property");
      } else if (e == "小区服务") {
        this.$router.push("/estate");
      } else if (e == "家政服务") {
        this.$router.push("/housekeeping");
      } else if (e == "公司介绍") {
        this.$router.push("/introduce");
      } else if (e == "公司软著") {
        this.$router.push("/softening");
      } else if (e == "联系我们") {
        this.$router.push("/contact");
      } else if (e == "新闻资讯") {
        this.$router.push("/information");
      } else if (e == "商务合作") {
        this.$router.push("/business");
      } else if (e == "APP下载") {
        this.$router.push("/download");
      } else if (e == "新闻动态") {
        this.$router.push("/news");
      } else if (e == "蜻蜓到家") {
        this.$router.push("/qtdj");
      }
    },
    shouye() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="less" scoped>
#tabbar {
  width: 1920px;
  height: 76px;
  background: rgba(255, 255, 255, 0.72);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  .tab {
    width: 1200px;
    margin: 0 360px;
    height: 76px;
    .tab-icon {
      width: 43px;
      height: 36px;
      background-image: url(~@/assets/logo-huang.png);
      background-size: 100% 100%;
    }
    .tab-box {
      height: 76px;
      box-sizing: border-box;
      // font-size: 14px;
      .tab-box-li {
        padding: 0 6px;
        line-height: 76px;
        margin-right: 100px;
        box-sizing: border-box;
        position: relative;
        .tab-box-li-tiao {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 3px;
          background: #f8951d;
        }
      }
      .tab-box-poup {
        position: relative;
        #no {
          width: 166px;
          // height: 200px;
          background: #ffffff;
          padding: 8px 0;
          box-shadow: 4px 4px 8px 1px rgba(18, 17, 39, 0.05),
            -4px -4px 8px 1px rgba(18, 17, 39, 0.05);
          border-radius: 10px 10px 10px 10px;
          display: none;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 76px;
          p {
            width: 166px;
            height: 50px;
            line-height: 50px;
            color: #333333;
            font-size: 16px;
            box-sizing: border-box;
          }
          p:hover {
            background: #f8951d;
            color: #fff;
          }
          .ps {
            background: #f8951d;
            color: #fff;
          }
        }
      }
      .tab-box-poup:hover #no {
        display: block;
      }
      .tab-box-li:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>